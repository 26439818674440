@charset "utf-8";
@import "vars";
@import "flexbox";

/* ====================================================
Base style & container
==================================================== */
body {
    color: #333333;
    font-size: 100%;
    line-height: 1.4;
    font-family: $font-gothic;
}

.container {
    display: block;
    margin: 0 auto;
    max-width: 1030px;
    padding: 0 15px;
}

.box_section {
    margin-bottom: 50px;

    &.mb_few {
        margin-bottom: 30px;
    }
}

.sp {
    display: none;
}

.fwb {
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .sp {
        display: block;
    }
    .pc {
        display: none;
    }
}

/* ====================================================
Float & clear
==================================================== */
.clearfix {
    *zoom: 1;

    &:before {
        display: table;
        content: '';
    }

    &:after {
        display: table;
        content: '';
        clear: both;
    }
}

/* ====================================================
Header & footer
==================================================== */
#header {
    .bg-main {
        height: 100vh;
        background: url(../img/common/bnr-main.png) center no-repeat;
        background-size: cover;
        @media screen and (max-width: 768px) {
            height: 400px;
        }

        .container {
            padding-top: 35px;
            @media screen and (max-width: 768px) {
                padding-top: 10px;
            }

            .logo {
                width: 200px;
                float: left;
                position: relative;
                .extlink {
                    white-space: nowrap;
                    padding-left: 55px;
                    a {
                        display: inline-block;
                        margin-right: 10px;
                        font-weight: bold;
                        border-right: 1px solid #666666;
                        padding-right: 10px;
                        color: #FC0000;
                        text-decoration: underline;
                        &:last-child {
                            border-right: none;
                            padding-right: 0;
                        }
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .nav-top {
                width: calc(100% - 220px);
                float: right;

                ul {
                    text-align: right;

                    li {
                        width: 18%;
                        padding: 0 1%;
                        text-align: center;
                        float: right;

                        a {
                            /* サービス内容: */
                            font-weight: bold;
                            @include font-size(16);
                            color: #000000;
                            letter-spacing: 0;
                            @media(max-width: 900px) {
                                @include font-size(13);
                            }

                            span {
                                /* Service: */
                                display: block;
                                margin-top: 4px;
                                @include font-size(12);
                                color: #000000;
                                letter-spacing: 0;
                            }
                        }

                        &.btn-li {
                            width: 22.514%;
                            @media(max-width: 768px) {
                                width: 100%;
                                padding: 20px 5px;
                            }

                            a {
                                display: block;
                                width: 100%;
                                height: 40px;
                                line-height: 40px;
                                /* Rectangle 2 Copy: */
                                background: #C4182D;
                                color: #FFFFFF;
                                border-radius: 6px;
                                @media(max-width: 768px) {
                                    height: auto;
                                    line-height: 1.4;
                                    max-width: 250px;
                                    margin: 0 auto;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }

            .txt-bnr {
                text-align: center;
                padding-top: 170px;
                @media screen and (max-width: 768px) {
                    padding-top: 70px;
                }

                img {
                    max-width: 100%;
                }

                a {
                    display: inline-block;
                    width: 300px;
                    height: 50px;
                    margin-top: 50px;
                    line-height: 50px;
                    background: #C4182D;
                    border-radius: 6px;
                    /* 詳しく見る: */
                    @include font-size(18);
                    color: #FFFFFF;
                    letter-spacing: 0;
                    font-weight: bold;
                    @media screen and (max-width: 768px) {
                        margin-top: 50px;
                        width: 100%;
                        max-width: 250px;
                    }
                }
            }
        }
    }
}

#footer {
    p {
        padding: 20px 0;
        /* Member of NAL group: */
        @include font-size(14);
        color: #000000;
        text-align: center;
        letter-spacing: 0;
        /* VTD株式会社 住所：ベトナムハノイ市C: */
        line-height: 24px;
    }

    .copyright {
        /* Rectangle 3 Copy 5: */
        padding: 20px 0;
        background: #2C2C2C;
        text-align: center;
        /* Copyright© VTD JSC.: */
        font-weight: bold;
        @include font-size(12);
        color: #FFFFFF;
        letter-spacing: 0;
    }

    a {
        color: #000000;
    }
}

/* ====================================================
Components
==================================================== */
#back-to-top {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    visibility: hidden;
    @include smooth-transition;

    &.display {
        visibility: visible;
        opacity: 1;
        @include smooth-transition;
    }

    &.remove-fixed {
        position: absolute;
        right: 15px;
        top: -100px;

        img {
            position: relative;
            bottom: 20px;
        }
    }
}

/* ====================================================
Typography
==================================================== */
.ttl-box-term {
    @include font-size(26);
    color: #666666;
    padding-bottom: 20px;
}

.box-color {
    display: inline-block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.color {
    &-main {
        background: $main-color;
    }

    &-blue {
        background: $blue-color;
    }

    &-black {
        background: $black-color;
    }

    &-gray {
        background: $gray-color;
    }
}

.list-color {
    li {
        float: left;
        width: 54px;
        text-align: center;
        @include font-size(12);
    }
}

.left {
    float: left;
}

.right {
    float: right;
}

p {
    @include font-size(14);
    line-height: 1.7;
    @media screen and (max-width: 768px) {
        @include font-size(13);
    }

    &.small {
        @include font-size(11);
        line-height: 1.4;
    }
}

.txt {
    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }

    &-left {
        text-align: left;
    }
}

/* ====================================================
Buttons, input, .etc
==================================================== */

.inner {
    display: block;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
    @media(max-width: 768px) {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 768px) {
    #globalmenu {
        ul {
            display: none;
        }
    }
    #switchBtnArea {
        position: relative;
    }
    #switchBtn {
        top: 20px;
        right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 5px;
        position: absolute;
        outline: none;
        z-index: 10000;

        .menu-trigger {
            position: relative;
            width: 30px;
            height: 40px;

            span {
                position: absolute;
                left: 0;
                width: 100%;
                height: 3px;
                background-color: #666666;
            }
        }
    }
    #switchBtn .menu-trigger, #switchBtn .menu-trigger span {
        display: inline-block;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        box-sizing: border-box;
    }
    #switchBtn .menu-trigger span:nth-of-type(1) {
        top: 9px;
    }
    #switchBtn .menu-trigger span:nth-of-type(2) {
        top: 18px;
    }
    #switchBtn .menu-trigger span:nth-of-type(3) {
        top: 27px;
    }
    #switchBtn.btnClose .menu-trigger span:nth-of-type(1) {
        -webkit-transform: translateY(3px) rotate(-45deg);
        transform: translateY(3px) rotate(-45deg);
    }
    #switchBtn.btnClose .menu-trigger span:nth-of-type(2) {
        -webkit-transform: translateY(-6px) rotate(45deg);
        transform: translateY(-6px) rotate(45deg);
    }
    #switchBtn.btnClose .menu-trigger span:nth-of-type(3) {
        display: none;
    }

    #rwdMenuWrap {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        ul {
            border-bottom: 1px solid #666666;
            width: 100%;
            display: none;
            margin: 0;
            padding: 50px 5% 5px;
            background-color: #FFFFFF;
            overflow: auto;
            height: 400px;

            li {
                width: 100% !important;
                border-bottom: #A1948A 1px dashed;

                &:last-child {
                    border-bottom: none;
                }

                a {
                    position: relative;
                    padding: 10px 20px;
                    text-align: left;
                    display: block;
                    text-decoration: none;
                    color: #666666;
                }
            }
        }
    }
}