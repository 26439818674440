h2.ttl-sec {
    /* Company Info: */
    /* Company Info: */
    font-family: $font-gothic;
    @include font-size(40);
    font-weight: bold;
    text-align: center;
    color: #C4182D;
    letter-spacing: 0;
    line-height: 80px;
}

h3.sub-ttl-sec {
    /* 会社案内: */
    font-family: $font-gothic;
    @include font-size(20);
    font-weight: bold;
    text-align: center;
    color: #000000;
    letter-spacing: 0;
    line-height: 40px;
}

h4 {
    position: relative;
    display: inline-block;
    font-family: $font-gothic;
    @include font-size(20);
    font-weight: bold;
    padding: 0 100px;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
        padding: 0 40px;
        @include font-size(16);
    }

    &:after {
        position: absolute;
        top: 15px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #C4182D;
        content: "";
        z-index: 1;
    }

    span {
        position: relative;
        display: inline-block;
        padding: 0 15px;
        background: #ffffff;
        z-index: 2;
    }
}

.service {
    padding: 60px 0 0;

    .specialty {
        padding-top: 60px;
        text-align: center;
        @media screen and (max-width: 768px) {
            padding-top: 30px;
        }
    }

    .list-specialty {
        margin-top: 20px;

        & > li {
            width: 32.5%;
            float: left;
            margin-right: 1%;
            background: #F4F4F4;
            padding: 35px 25px 20px;
            @media screen and (max-width: 768px) {
                width: 100%;
                margin-right: 0;
                padding: 15px 10px;
            }

            &:last-child {
                margin-right: 0;
            }

            .ttl-list {
                text-align: center;
                @include font-size(20);
                font-weight: bold;
                padding-bottom: 20px;
                min-height: 80px;
                color: #C4182D;
                @media screen and (max-width: 768px) {
                    @include font-size(18);
                    padding-bottom: 15px;
                    min-height: auto;
                }
            }

            &:nth-child(2) {
                background: #EBEBEB;
            }

            &:nth-child(2), &:nth-child(3) {
                .ttl-list {
                    padding-top: 10px;
                }
            }

            ul {
                li {
                    /* ①日本からいただくデザインデータに て、: */
                    @include font-size(16);
                    position: relative;
                    color: #000000;
                    padding-left: 20px;
                    letter-spacing: 0;
                    line-height: 30px;
                    text-align: left;
                    @media screen and (max-width: 768px) {
                        @include font-size(14);
                        line-height: 1.8;
                    }

                    &:after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        content: "①";
                    }

                    &:nth-child(2) {
                        &:after {
                            content: "②";
                        }
                    }

                    &:nth-child(3) {
                        &:after {
                            content: "③";
                        }
                    }
                }
            }
        }
    }

    .specialty-2 {
        padding-top: 80px;
        @media screen and (max-width: 768px) {
            padding-top: 20px;
        }

        p.ttl {
            text-align: center;
            @include font-size(20);
            font-weight: bold;
            line-height: 35px;
            @media screen and (max-width: 768px) {
                @include font-size(16);
                line-height: 1.8;
            }
        }

        .img {
            text-align: center;
            padding-top: 40px;
        }
    }

    .team {
        padding: 80px 0 100px;
        text-align: center;
        @media screen and (max-width: 768px) {
            padding: 40px 0 50px;
        }

        .list-specialty {
            & > li {
                background: #ffffff;
                border: 2px solid #C4182D;
                padding: 0;

                .ttl-list {
                    @include display-flex;
                    @include justify-content(center);
                    @include align-items(center);
                    background: #C4182D;
                    color: #ffffff;
                    height: 87px;
                    padding: 0;
                    @include font-size(26);

                    span {
                        @include font-size(26);
                    }

                    @media screen and (max-width: 768px) {
                        @include font-size(20);
                        span {
                            @include font-size(26);
                        }
                        height: 50px;
                    }
                }

                &:nth-child(3) {
                    .ttl-list {
                        @include font-size(20);
                    }
                }

                .ct {
                    padding: 20px 25px;
                    @media screen and (max-width: 768px) {
                        padding: 15px 10px;
                    }

                    p {
                        text-align: left;
                        margin-bottom: 20px;
                        @include font-size(16);
                        @media screen and (max-width: 768px) {
                            @include font-size(14);
                        }
                    }
                }
            }
        }
    }
}

.works {
    background: url(../img/common/bg-01.png) center;
    background-size: cover;
    padding: 60px 0 15px;
    @media screen and (max-width: 768px) {
        padding: 20px 0 30px;
        .container {
            padding: 0;
        }
    }

    .sub-ttl-sec {
        color: #ffffff;
    }

    .ttl-box {
        text-align: center;
        margin-top: 30px;

        h4 {
            span {
                background: none;
                color: #ffffff;
            }

            &:after {
                width: 100px;
                left: 0;
                @media screen and (max-width: 768px) {
                    width: 40px;
                    top: 10px;
                }
            }

            &:before {
                width: 100px;
                position: absolute;
                top: 15px;
                right: 0;
                height: 1px;
                background: #C4182D;
                content: "";
                z-index: 1;
                @media screen and (max-width: 768px) {
                    width: 40px;
                    top: 10px;
                }
            }
        }
    }

    .table-responsive {
        @media screen and (max-width: 768px) {
            overflow: auto;
        }
    }

    table {
        margin-top: 20px;
        margin-bottom: 70px;
        width: 100%;
        border: 2px solid #ABABAB;
        @include font-size(12);
        border: collapse;
        @media screen and (max-width: 768px) {
            width: 800px;
            margin-bottom: 0;
        }

        th, td {
            border: 1px solid #D8D8D8;
        }

        thead {
            th {
                background: #EBEBEB;
                font-weight: bold;
                padding: 15px;
            }
        }

        tbody {
            th, td {
                background: #ffffff;
                font-weight: bold;
                padding: 10px 15px;
            }
        }
    }

}

.company_info {
    padding: 60px 0 0;
    @media screen and (max-width: 768px) {
        padding: 20px 0 0;
    }

    table {
        width: 596px;
        margin: 20px auto;
        @media screen and (max-width: 768px) {
            width: 100%;
        }

        th {
            width: 80px;
            vertical-align: top;
            @include font-size(15);
            font-weight: bold;
            padding-top: 2px;
            @media screen and (max-width: 768px) {
                width: 100%;
                display: block;
            }
        }

        td {
            line-height: 20px;
            padding-left: 10px;
            padding-bottom: 20px;
            @include font-size(15);
            @media screen and (max-width: 768px) {
                width: 100%;
                display: block;
                padding-left: 0;
                padding-top: 10px;
            }
        }
    }

    .box-img {
        padding: 40px;
        @media screen and (max-width: 768px) {
            padding: 20px 0;
        }

        .left {
            width: 50%;
            float: left;
            text-align: right;
            @media screen and (max-width: 768px) {
                width: 100%;
                margin-bottom: 10px;
                img {
                    width: 100%;
                }
            }
        }

        .right {
            width: 50%;
            float: right;
            text-align: left;
            @media screen and (max-width: 768px) {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }
}

.contact {
    background: url(../img/common/bg-02.png) center;
    background-size: cover;
    color: #ffffff;
    padding: 60px 0;
    @media screen and (max-width: 768px) {
        padding: 20px 0;
    }

    h3.sub-ttl-sec {
        color: #ffffff;
    }

    .content {
        width: 650px;
        margin: 0 auto;
        @media screen and (max-width: 768px) {
            width: 100%;
        }

        .ttl {
            padding: 10px 20px;
            text-align: center;
            @include font-size(12);
            font-weight: bold;
        }

        .ico {
            text-align: center;
            margin-top: 20px;

            a {
                color: #ffffff;
                display: inline-block;
                position: relative;
                @include font-size(12);
                font-weight: bold;
                padding-left: 35px;
                margin-right: 20px;
                @media screen and (max-width: 768px) {
                    margin-bottom: 30px;
                    margin-right: 10px;
                }

                &:before {
                    position: absolute;
                    top: -1px;
                    left: 0;
                    content: url(../img/common/mail.png);
                }

                &.tel {
                    &:before {
                        position: absolute;
                        top: -4px;
                        left: 0;
                        content: url(../img/common/tell.png);
                    }
                }
            }
        }

        .box-contact {
            margin-top: 100px;
            @media screen and (max-width: 768px) {
                margin-top: 20px;
            }

            p {
                text-align: center;
                @include font-size(20);
                font-weight: bold;
                @media screen and (min-width: 769px) {
                    // padding-left: 115px;
                }
            }

            label.error {
                position: relative;
                display: block;
                color: red;
                margin-top: 10px;
                margin-bottom: -10px;
                @include font-size(12);
            }

            table {
                width: 100%;
                margin-top: 20px;

                th {
                    @include font-size(14);
                    font-weight: bold;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                        display: block;
                    }
                }

                td {
                    padding: 10px;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                        display: block;
                        padding: 10px 0;
                    }

                    input {
                        width: 100%;
                        height: 44px;
                        line-height: 44px;
                        @include font-size(14);
                        border: 2px solid #CBCBCB;
                        border-radius: 8px;
                        padding: 2px 10px;

                        &.error {
                            color: red;
                            border-color: #d41c1c;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    textarea {
                        width: 100%;
                        @include font-size(14);
                        border: 2px solid #CBCBCB;
                        border-radius: 8px;
                        padding: 5px 10px;

                        &.error {
                            color: red;
                            border-color: #d41c1c;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    .btn {
                        display: inline-block;
                        text-align: center;
                        width: 200px;
                        height: 50px;
                        line-height: 50px;
                        background: #C4182D;
                        @include font-size(18);
                        font-weight: bold;
                        color: #ffffff;
                        border: none;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}

.back-top {
    img {
        width: 60px;
    }
}
/* for recaptcha hh */
.text-xs-center {
    text-align: center;
}

.g-recaptcha {
    display: inline-block;
}